<template>
  <div>
    <FullCalendar
      :options="{
        ...calendarOptions,
        events: premiseCollections,
      }"
    />
  </div>
</template>

<script>
import store from "@/store/index";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  computed: {
    premiseCollections: function () {
      return store.state.app.selectedPremiseCollections;
    },
    machineID: function () {
      return store.state.app.selectedPremiseID;
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: "listYear",
        headerToolbar: {
          left: "",
          center: "",
          right: "",
        },
        buttonText: {
          today: "Today",
          month: "Calendar",
          list: "List",
        },
      },
    };
  },
};
</script>
