<template>
  <!-- Error page-->
  <div class="list-wrapper">
    <b-row class="p-1 m-0">
      <div class="col-12 text-center">
        <b-img :src="appLogoImageBlue" alt="logo" style="height: 100px" />
      </div>
    </b-row>
    <b-row class="print-row p-1 m-0">
      <div class="col-12">
        <b-button @click="back" size="sm" variant="primary"><< back</b-button>
        <b-button @click="print" size="sm" variant="primary" class="float-right"
          >print/save</b-button
        >
      </div>
    </b-row>
    <calendar />
  </div>
  <!-- / Error page-->
</template>

<script>
import store from "@/store/index";
import Calendar from "../calendar/CalendarPrint.vue";
import { BRow, BCol, BButton, BImg } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    Calendar,
    BButton,
    BRow,
    BCol,
    BImg,
  },

  data() {
    return {};
  },
  computed: {
    nextCollections: function () {
      return store.state.app.selectedPremiseCollections;
    },
  },
  methods: {
    print() {
      document.title = "Collection Calendar";
      window.print();
    },
    back() {
      this.$router.push("/");
    },
  },
  setup(props) {
    // App Name
    const { appLogoImageBlue } = $themeConfig.app;

    return {
      appLogoImageBlue,
    };
  },
};
</script>
<style>
.fc.fc-media-screen {
  padding: 0 2rem;
}
body {
  background-color: #fff !important;
}
@media print {
  html[dir] .content.app-content {
    padding: 10px !important;
  }
  add-to-ios {
    display: none !important;
  }

  .fc-scroller.fc-scroller-liquid {
    overflow: visible !important;
  }
  #trengo-web-widget {
    display: none !important;
  }
  .print-row,
  .fc > .fc-header-toolbar,
  [dir="ltr"] .navbar-floating .header-navbar-shadow,
  footer,
  nav.navbar {
    display: none !important;
  }
}
</style>
